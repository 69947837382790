<template>
  <div>
    <!--Label-->
    <span
      v-if="sideLabel"
      class="nsfgrey--text caption pb-0"
    >{{ `${label}: ` }}</span>
    <span
      v-else
      class="nsfgrey--text caption pb-0"
    >{{ label }}<br></span>
    <!--Error Text-->
    <span
      v-if="errorText"
      class="error white--text body-1 px-2"
      :class="displayBold === true ? 'font-weight-bold' : ''"
    >{{ displayString }}</span>
    <!--Text-->
    <span
      v-else-if="displayString"
      class="secondary--text body-1"
      :class="displayBold === true ? 'font-weight-bold' : ''"
    >{{ displayString }}</span>
    <!--Number-->
    <span
      v-else-if="displayNumber"
      class="secondary--text body-1"
      :class="displayBold === true ? 'font-weight-bold' : ''"
    >{{ displayNumber }}</span>
    <!--Array To Text-->
    <span
      v-else-if="displayArray"
      class="secondary--text body-1"
      :class="displayBold === true ? 'font-weight-bold' : ''"
    >{{ arrayToText }}</span>
    <span
      v-else
      class="mb-4"
    ><br></span>
  </div>
</template>

<script>
export default {
  name: 'TextDisplay',
  props: {
    label: {
      type: String,
      required: false,
    },
    displayString: {
      type: String,
      required: false,
    },
    displayNumber: {
      type: Number,
      required: false,
    },
    displayArray: {
      type: Array,
      required: false,
    },
    displayBold: {
      type: Boolean,
      required: false,
    },
    errorText: {
      type: Boolean,
      required: false,
    },
    sideLabel: {
        type: Boolean,
        required: false,
        default: false
    }
  },
  computed: {
    arrayToText() {
      return this.displayArray.join();
    },
  },
};
</script>

<style>

</style>

