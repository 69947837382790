<template>
  <div>
    <!--Progress Bar-->
    <v-dialog v-model="progress" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--File Upload Progress Bar-->
    <v-dialog v-model="upload" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          File Uploading
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog Box -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Delete?
          <v-spacer />
          <v-btn text icon color="white" @click="deleteDialog = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                {{ deleteMessage }} will be deleted from the system!
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="deleteMode === 1 || deleteMode === 1.1"
            block
            rounded
            color="error"
            @click.native="removeItem()"
          >
            <v-icon small left> fal fa-trash </v-icon>Delete
          </v-btn>
          <v-btn
            v-if="deleteMode === 2"
            block
            rounded
            color="error"
            @click.native="removeDecorating(deleteRep)"
          >
            <v-icon small left> fal fa-trash </v-icon>Delete
          </v-btn>
          <v-btn
            v-if="deleteMode === 3"
            block
            rounded
            color="error"
            @click.native="removeFile(deleteRep)"
          >
            <v-icon small left> fal fa-trash </v-icon>Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload Artwork-->
    <v-dialog v-model="uploadArtwork" persistent max-width="500px">
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Upload File
          <v-spacer />
          <v-btn text icon color="white" @click="uploadArtwork = false">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="artwork">
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="fileDescription"
                    :rules="rules.fileDescription"
                    label="Description"
                    flat
                    hide-details
                    placeholder=" "
                    persistent-placeholder
                    required
                  />
                </v-flex>
                <v-flex v-if="order.f_type !== 3" xs12>
                  <v-autocomplete
                    v-model="fileLocation"
                    :items="designLocationsStore.designLocations"
                    :rules="rules.fileLocation"
                    item-text="d_name"
                    item-value="d_name"
                    label="Location"
                    placeholder=" "
                    persistent-placeholder
                    required
                  />
                </v-flex>
                <v-flex xs12>
                  <v-file-input
                    ref="file"
                    v-model="files"
                    :rules="rules.files"
                    multiple
                    clearable
                    clear-icon="fal fa-times-circle"
                    prepend-icon="fal fa-paperclip"
                    label="Select File"
                    placeholder=" "
                    persistent-placeholder
                    required
                  />
                </v-flex>
                <v-flex xs12>
                  <v-btn
                    rounded
                    block
                    dark
                    color="primary"
                    class="mb-2"
                    @click="addFile()"
                  >
                    <v-icon small left> fal fa-check </v-icon>Submit
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="!progress" fluid fill-height grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12>
          <v-card flat color="accent">
            <v-toolbar flat dense dark color="secondary">
              <v-icon>fal fa-shopping-cart</v-icon>
              <v-toolbar-title class="pl-2">
                Order Entry
              </v-toolbar-title>
              <v-spacer />
              <v-btn text icon color="white" @click="goOrder(order.uuid)">
                <v-icon>fal fa-times</v-icon>
              </v-btn>
            </v-toolbar>

            <v-stepper v-model="e1">
              <v-stepper-header class="elevation-0">
                <template v-for="n in steps">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="e1 > n"
                    :step="n"
                    complete-icon="far fa-check"
                  >
                    {{ stepHeader(n) }}
                  </v-stepper-step>
                </template>
              </v-stepper-header>

              <v-divider />

              <v-stepper-items class="elevation-0">
                <!-- General Info -->
                <v-stepper-content step="1" class="elevation-0">
                  <v-form ref="step1" v-model="step1Valid" lazy-validation>
                    <v-container fluid grid-list-md>
                      <v-layout row wrap>
                        <!------------ Left Side ------------>
                        <v-flex xs12 sm6>
                          <!--Order Type-->
                          <v-layout>
                            <v-flex xs12 class="py-0">
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >What type of order is this?</span
                              >
                              <v-radio-group
                                v-model="order.f_type"
                                :rules="rules.f_type"
                                row
                                required
                                class="my-1"
                              >
                                <v-radio
                                  label="Embroidery"
                                  :value="1"
                                  color="primary"
                                />
                                <v-radio
                                  label="Screen Printing"
                                  :value="2"
                                  color="primary"
                                />
                                <v-radio
                                  label="PPE Items"
                                  :value="3"
                                  color="primary"
                                />
                              </v-radio-group>
                            </v-flex>
                          </v-layout>

                          <!--New or repeat-->
                          <v-layout>
                            <v-flex xs12 class="py-0">
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >Is this a new order or repeat order?</span
                              >
                              <v-radio-group
                                v-model="order.f_new"
                                :rules="rules.f_new"
                                row
                                required
                                class="my-1"
                              >
                                <v-radio
                                  label="New"
                                  :value="1"
                                  color="primary"
                                />
                                <v-radio
                                  label="Repeat"
                                  :value="2"
                                  color="primary"
                                />
                              </v-radio-group>
                            </v-flex>
                          </v-layout>

                          <!--Digitizing Or Supplied For New Embroidery Order-->
                          <v-layout
                            v-if="order.f_type === 1 && order.f_new === 1"
                          >
                            <v-flex xs12 class="py-0">
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >Does this need to be digitized or is it a
                                supplied design?</span
                              >
                              <v-radio-group
                                v-model="order.f_design"
                                :rules="rules.f_design"
                                row
                                required
                                class="my-1"
                              >
                                <v-radio
                                  label="Supplied"
                                  :value="1"
                                  color="primary"
                                />
                                <v-radio
                                  label="Digitized"
                                  :value="2"
                                  color="primary"
                                />
                              </v-radio-group>
                            </v-flex>
                          </v-layout>

                          <!--Repeat Purchase Order Number-->
                          <v-layout v-if="order.f_new === 2">
                            <v-flex xs12 md6 class="pb-4">
                              <v-text-field
                                v-model="order.d_customerPORepeat"
                                label="Previous Order PO#"
                                placeholder=" "
                                persistent-placeholder
                                hide-details
                                class="mb-1"
                              />
                            </v-flex>
                          </v-layout>

                          <!--Ship Or Pick Up-->
                          <v-layout>
                            <v-flex xs12 class="pt-2">
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >How will this order be delivered?</span
                              >
                              <v-radio-group
                                v-model="order.f_pickUp"
                                :rules="rules.f_pickUp"
                                row
                                required
                                class="my-1"
                              >
                                <v-radio
                                  label="Ship"
                                  :value="1"
                                  color="primary"
                                />
                                <v-radio
                                  label="Pick Up"
                                  :value="2"
                                  color="primary"
                                />
                              </v-radio-group>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <!------------ Right Side ------------>
                        <v-flex xs12 sm6>
                          <!--Purchase Order-->
                          <v-layout>
                            <v-flex xs12 class="py-0">
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >Provide a PO# for invoicing purposes</span
                              >
                              <v-text-field
                                v-model="order.d_customerPurchaseOrder"
                                :rules="rules.d_customerPurchaseOrder"
                                label=""
                                placeholder=" "
                                persistent-placeholder
                                required
                              />
                            </v-flex>
                          </v-layout>

                          <!--Job Name-->
                          <v-layout>
                            <v-flex xs12 class="py-0">
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >Provide a job name for this order</span
                              >
                              <v-text-field
                                v-model="order.d_jobName"
                                :rules="rules.d_jobName"
                                label=""
                                placeholder=" "
                                persistent-placeholder
                                required
                              />
                            </v-flex>
                          </v-layout>

                          <!--Contact-->
                          <v-layout>
                            <v-flex xs12>
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >Salesperson/Contact for this order</span
                              >
                              <v-autocomplete
                                v-model="order.fk_userID"
                                :items="usersStore.users"
                                :rules="rules.fk_userID"
                                item-text="name"
                                item-value="id"
                                label=""
                                placeholder=" "
                                persistent-placeholder
                                required
                              />
                            </v-flex>
                          </v-layout>

                          <!--Rush-->
                          <v-layout>
                            <v-flex xs12 sm4>
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >Is this order a rush?</span
                              >
                              <v-select
                                v-model="order.f_priority"
                                :items="priorities"
                                item-text="name"
                                item-value="id"
                                label=""
                                placeholder=" "
                                persistent-placeholder
                              />
                            </v-flex>

                            <!--Ship Date-->
                            <v-flex xs12 md8>
                              <span
                                class="grey--text text--darken-1 body-2 py-0"
                                >Ship Date?</span
                              >
                              <v-menu
                                v-model="menu1"
                                :close-on-content-click="false"
                                box
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="order.d_dateDue"
                                    placeholder=" "
                                    persistent-placeholder
                                    label=""
                                    append-icon="fal fa-calendar-alt"
                                    required
                                    :rules="rules.d_dateDue"
                                    v-on="on"
                                    @blur="date1 = parseDate(order.d_dateDue)"
                                  />
                                </template>
                                <v-date-picker
                                  v-model="date1"
                                  color="primary"
                                  @input="menu1 = false"
                                />
                              </v-menu>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                      <v-card-actions class="pa-2">
                        <v-spacer />
                        <v-btn
                          rounded
                          color="primary"
                          @click="saveStep1(order.uuid)"
                        >
                          <v-icon small left>
                            fal fa-arrow-alt-down
                          </v-icon>
                          Save & Continue
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-form>
                </v-stepper-content>

                <!--Line Items-->
                <v-stepper-content step="2" class="elevation-0">
                  <v-form ref="step2" v-model="step1Valid" lazy-validation>
                    <!--Screen Printing & Embroidery - Decorating-->
                    <v-container v-if="order.f_type !== 3" fluid grid-list-md>
                      <v-layout row wrap>
                        <!--New Line Item-->
                        <v-flex xs12 md3 px-0 pt-0>
                          <v-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            rounded
                            color="green lighten-1 white--text"
                            @click="addItem()"
                          >
                            <v-icon small left> fal fa-plus </v-icon>New Line
                            Item
                          </v-btn>
                        </v-flex>

                        <!--Line Item Pagination-->
                        <v-flex xs12 md6 px-0 pt-0 text-center>
                          <!--First-->
                          <v-btn
                            :disabled="currentItem === 1"
                            icon
                            @click="paginate(1)"
                          >
                            <v-icon color="primary">
                              fal fa-step-backward
                            </v-icon>
                          </v-btn>

                          <!--Previous-->
                          <v-btn
                            :disabled="currentItem === 1"
                            icon
                            @click="paginate(currentItem - 1)"
                          >
                            <v-icon color="primary">
                              fal fa-caret-left
                            </v-icon>
                          </v-btn>

                          <span
                            >{{ currentItem }} of
                            {{ order.j_line_items.length }}</span
                          >

                          <!--Next-->
                          <v-btn
                            :disabled="
                              currentItem === order.j_line_items.length
                            "
                            icon
                            @click="paginate(currentItem + 1)"
                          >
                            <v-icon color="primary">
                              fal fa-caret-right
                            </v-icon>
                          </v-btn>

                          <!--Last-->
                          <v-btn
                            :disabled="
                              currentItem === order.j_line_items.length
                            "
                            icon
                            @click="paginate(order.j_line_items.length)"
                          >
                            <v-icon color="primary">
                              fal fa-step-forward
                            </v-icon>
                          </v-btn>
                        </v-flex>

                        <!--Delete Line Item-->
                        <v-flex
                          v-if="order.j_line_items.length > 0"
                          xs12
                          md3
                          px-0
                          pt-0
                          text-right
                        >
                          <v-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            rounded
                            color="red lighten-1 white--text"
                            @click="openDeleteDialog(1, 'Line item ', '')"
                          >
                            <v-icon small left> fal fa-trash </v-icon>Delete
                            Line Item
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <v-divider />

                      <!--Line Items-->
                      <v-layout row wrap>
                        <v-flex xs12 md2>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1]
                                .d_productItemNumber
                            "
                            label="Product Item/Style"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>

                        <v-flex xs12 md2>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_color
                            "
                            label="Color"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>

                        <v-flex xs12 md8>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_description
                            "
                            label="Description"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                      </v-layout>

                      <v-layout row wrap>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeXS
                            "
                            label="XS"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeSM
                            "
                            label="SM"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeMD
                            "
                            label="MD"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeLG
                            "
                            label="LG"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeXL
                            "
                            label="XL"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeXXL
                            "
                            label="XXL"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeXXXL
                            "
                            label="XXXL"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_size4XL
                            "
                            label="4XL"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_size5XL
                            "
                            label="5XL"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeOSFA
                            "
                            label="OSFA"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            v-model="
                              order.j_line_items[currentItem - 1].d_sizeOther
                            "
                            label="Other"
                            placeholder=" "
                            persistent-placeholder
                            hide-details
                          />
                        </v-flex>
                        <v-flex xs12 md1>
                          <v-text-field
                            readonly
                            label="Total"
                            placeholder=" "
                            persistent-placeholder
                            disabled
                            :value="lineItemTotal"
                          />
                        </v-flex>
                      </v-layout>

                      <!--Decorating-->
                      <v-layout row wrap>
                        <v-flex xs12 md6 px-0 pt-0>
                          <v-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            rounded
                            color="green lighten-1 white--text"
                            @click="addDecorating()"
                          >
                            <v-icon small left> fal fa-plus </v-icon>Decorating
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <template
                        v-for="(image, i) in order.j_line_items[currentItem - 1]
                          .j_images"
                      >
                        <v-layout row wrap>
                          <v-flex xs12 md3>
                            <v-autocomplete
                              v-model="image.akf_imageLocationID"
                              :items="designLocationsStore.designLocations"
                              :rules="rules.akf_imageLocationID"
                              item-text="d_name"
                              item-value="fk_internalID"
                              label="Location"
                              placeholder=" "
                              persistent-placeholder
                              required
                            />
                          </v-flex>
                          <v-flex xs12 md3>
                            <v-autocomplete
                              v-model="image.akf_itemService"
                              :items="servicesStore.services"
                              :rules="rules.akf_itemService"
                              item-text="d_name"
                              item-value="fk_internalID"
                              label="Service"
                              placeholder=" "
                              persistent-placeholder
                              required
                            />
                          </v-flex>
                          <v-flex v-if="image.d_designName" xs12 md2>
                            <v-text-field
                              v-model="image.d_designName"
                              label="Design Code"
                              placeholder=" "
                              persistent-placeholder
                              hide-details
                            />
                          </v-flex>
                          <v-flex xs12 md4>
                            <v-text-field
                              v-model="image.d_instructions"
                              label="Instructions"
                              placeholder=" "
                              persistent-placeholder
                              hide-details
                            />
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex xs12 md11 class="py-0">
                            <span class="grey--text text--darken-1 caption py-0"
                              >Options</span
                            >
                            <span class="red--text text--darken-1 caption py-0">
                              (Check All That Apply)</span
                            >
                            <v-radio-group row class="mt-0 pt-0">
                              <v-checkbox
                                v-for="check in servicesStore.options"
                                v-model="image.akf_itemOptions"
                                :label="check.d_name"
                                :value="check.fk_internalID"
                                class="mt-0 pr-4"
                                multiple
                                hide-details
                                color="primary"
                              />
                            </v-radio-group>
                          </v-flex>
                          <v-flex xs12 md1>
                            <v-btn
                              rounded
                              icon
                              color="red lighten-1 white--text"
                              @click="
                                openDeleteDialog(2, 'Decorating line ', i)
                              "
                            >
                              <v-icon>
                                fal fa-trash
                              </v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-divider />
                      </template>

                      <v-card-actions class="pa-2">
                        <v-btn outlined rounded @click="previous()">
                          <v-icon small left>
                            fal fa-arrow-alt-left
                          </v-icon>
                          Back
                        </v-btn>
                        <v-spacer />
                        <v-btn rounded color="primary" @click="saveStep2()">
                          <v-icon small left>
                            fal fa-arrow-alt-down
                          </v-icon>
                          Save & Continue
                        </v-btn>
                      </v-card-actions>
                    </v-container>

                    <!--PPE & Sublimation - Simple Line Item WIth Products-->
                    <v-container v-if="order.f_type === 3" fluid grid-list-md>
                      <v-layout row wrap>
                        <!--New Line Item-->
                        <v-flex xs12 md3 px-0 pt-0>
                          <v-btn
                            :block="$vuetify.breakpoint.smAndDown"
                            rounded
                            color="green lighten-1 white--text"
                            @click="addItem()"
                          >
                            <v-icon small left> fal fa-plus </v-icon>New Line
                            Item
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <v-divider />

                      <!--Line Items-->
                      <template v-for="(item, index) in order.j_line_items">
                        <v-layout :key="index" row wrap>
                          <v-flex xs12 md1>
                            <v-text-field
                              v-model="item.d_sizeOther"
                              label="Qty"
                              placeholder=" "
                              persistent-placeholder
                              hide-details
                              @blur="
                                pullProductDetails(
                                  index,
                                  item.fk_productID,
                                  item.d_sizeOther
                                )
                              "
                            />
                          </v-flex>

                          <v-flex xs12 md2>
                            <v-autocomplete
                              v-model="item.fk_productID"
                              :items="productsStore.productsValueList"
                              item-text="d_itemNumber"
                              item-value="id"
                              label="Product"
                              placeholder=" "
                              persistent-placeholder
                              hide-details
                              @input="
                                pullProductDetails(
                                  index,
                                  item.fk_productID,
                                  item.d_sizeOther
                                )
                              "
                            />
                          </v-flex>

                          <v-flex xs12 md1>
                            <text-display
                              :label="'Unit Price'"
                              :displayString="formatMoney(item.d_price)"
                            />
                          </v-flex>

                          <v-flex xs12 md7>
                            <v-text-field
                              v-model="item.d_description"
                              label="Description"
                              placeholder=" "
                              persistent-placeholder
                              hide-details
                            />
                          </v-flex>

                          <v-flex xs12 md1>
                            <v-btn
                              rounded
                              icon
                              color="red lighten-1 white--text"
                              @click="
                                openDeleteDialog(1.1, 'Line item ', index)
                              "
                            >
                              <v-icon>
                                fal fa-trash
                              </v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </template>

                      <v-card-actions class="pa-2">
                        <v-btn outlined rounded @click="previous()">
                          <v-icon small left>
                            fal fa-arrow-alt-left
                          </v-icon>
                          Back
                        </v-btn>
                        <v-spacer />
                        <v-btn rounded color="primary" @click="saveStep2()">
                          <v-icon small left>
                            fal fa-arrow-alt-down
                          </v-icon>
                          Save & Continue
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-form>
                </v-stepper-content>

                <!--Artwork-->
                <v-stepper-content step="3" class="elevation-0">
                  <v-card>
                    <v-container fluid grid-list-md>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-icon large color="secondary">
                            fal fa-file-upload
                          </v-icon>
                          <span class="headline pl-2 secondary--text"
                            >File Upload</span
                          >
                          <p class="py-2">
                            Provide any file to assist us completing the order
                            including artwork, order instructions, hard copy of
                            a purchase order, etc.
                          </p>
                          <p class="py-2">
                            Uploading of multiple files is allowed but they will
                            need to be uploaded undividually.
                          </p>
                          <p>
                            JPG, PNG, AI, PSD, PDF and XLSX file types are
                            supported. For faster upload times, especially with
                            larger file sizes, it is recommended to zip the file
                            before uploading.
                          </p>
                          <p class="error--text">
                            Files aren't saved with the order until the
                            <span class="primary--text">SAVE & CONTINUE</span>
                            is selected.
                          </p>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap class="pb-4">
                        <v-flex xs12 md2>
                          <v-btn
                            rounded
                            block
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="openArtworkDialog()"
                          >
                            <v-icon small left> fal fa-plus </v-icon>Upload File
                          </v-btn>
                        </v-flex>
                      </v-layout>

                      <v-divider />

                      <v-layout class="py-4">
                        <v-flex xs12>
                          <v-icon large color="secondary">
                            fal fa-images
                          </v-icon>
                          <span class="headline pl-2 secondary--text"
                            >Order Files</span
                          >

                          <v-list v-if="order.j_artwork" two-line class="pt-0">
                            <template v-for="(item, i) in order.j_artwork">
                              <v-list-item :key="i">
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    item.d_description
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle>{{
                                    item.d_location
                                  }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn
                                    text
                                    icon
                                    color="red"
                                    @click="
                                      openDeleteDialog(3, 'Artwork ', index)
                                    "
                                  >
                                    <v-icon>fal fa-trash</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider />
                            </template>
                          </v-list>

                          <v-list v-else>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="red--text subtitle-1 text-xs-center"
                                >
                                  No Files Exists For The Order
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-card-actions>
                      <v-btn outlined rounded @click="previous()">
                        <v-icon small left>
                          fal fa-arrow-alt-left
                        </v-icon>
                        Back
                      </v-btn>
                      <v-spacer />
                      <v-btn rounded color="primary" @click="saveStep3()">
                        <v-icon small left>
                          fal fa-arrow-alt-down
                        </v-icon>
                        Save & Continue
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <!--Delivery Options-->
                <v-stepper-content step="4" class="elevation-0">
                  <v-form ref="step3" v-model="step3Valid" lazy-validation>
                    <v-card>
                      <v-container fluid grid-list-md>
                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <!--Pick Up Or Ship-->
                            <v-layout row wrap>
                              <v-flex xs12 md11 class="py-0">
                                <span
                                  class="grey--text text--darken-1 caption py-0"
                                  >Delivery Method</span
                                >
                                <v-radio-group
                                  v-model="order.f_pickUp"
                                  row
                                  required
                                >
                                  <v-radio
                                    label="Ship"
                                    :value="1"
                                    color="primary"
                                  />
                                  <v-radio
                                    label="Pick Up"
                                    :value="2"
                                    color="primary"
                                  />
                                </v-radio-group>
                              </v-flex>
                            </v-layout>

                            <!--Ship From-->
                            <v-layout
                              v-if="order.f_pickUp && order.f_pickUp === 1"
                              row
                              wrap
                            >
                              <v-flex xs12 md11>
                                <v-autocomplete
                                  v-model="order.fk_addressID_From"
                                  :items="addressesStore.addressesReturn"
                                  item-text="d_display"
                                  item-value="id"
                                  label="Ship From"
                                  placeholder=" "
                                  persistent-placeholder
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-flex>
                            </v-layout>

                            <!--Ship To-->
                            <v-layout
                              v-if="order.f_pickUp && order.f_pickUp === 1"
                              row
                              wrap
                            >
                              <v-flex xs12 md9>
                                <v-autocomplete
                                  v-model="order.fk_addressID_To"
                                  :items="addressesStore.addressesShip"
                                  item-text="d_display"
                                  item-value="id"
                                  label="Ship To"
                                  placeholder=" "
                                  persistent-placeholder
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-flex>
                              <v-flex xs12 md2>
                                <address-entry :entry="address" :origin="1" />
                              </v-flex>
                            </v-layout>

                            <!--Shipping Carrier & Method-->
                            <v-layout
                              v-if="order.f_pickUp && order.f_pickUp === 1"
                              row
                              wrap
                            >
                              <v-flex xs12 md5 class="py-0">
                                <v-select
                                  v-model="order.fk_shippingCarrierID"
                                  :items="shippingCarriers"
                                  item-text="d_name"
                                  item-value="fk_internalID"
                                  label="Carrier"
                                  placeholder=" "
                                  persistent-placeholder
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                  @input="
                                    carrierAccounts(order.fk_shippingCarrierID)
                                  "
                                />
                              </v-flex>
                              <v-flex
                                v-if="order.f_pickUp && order.f_pickUp === 1"
                                xs12
                                md6
                                class="py-0"
                              >
                                <v-select
                                  v-model="order.fk_shippingMethodID"
                                  :items="shippingMethods"
                                  item-text="d_name"
                                  item-value="fk_internalID"
                                  label="Method"
                                  placeholder=" "
                                  persistent-placeholder
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-flex>
                            </v-layout>

                            <!--3rd Party Account-->
                            <v-layout
                              v-if="
                                order.f_pickUp &&
                                  order.f_pickUp === 1 &&
                                  carrierAccountsStore.carrierAccounts.length >
                                    0
                              "
                              row
                              wrap
                            >
                              <v-flex xs12 md9>
                                <v-autocomplete
                                  v-model="order.fk_carrierAccountID"
                                  :items="carrierAccountsStore.carrierAccounts"
                                  item-text="d_display"
                                  item-value="id"
                                  label="3rd Party Billing Account"
                                  placeholder=" "
                                  persistent-placeholder
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-flex>
                              <v-flex xs12 md2>
                                <carrier-account-entry
                                  :entry="carrierAccount"
                                />
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <!--Order Notes-->
                            <v-layout row wrap>
                              <v-flex xs12 class="py-0">
                                <v-textarea
                                  v-model="order.d_deliveryNote"
                                  label="Order Notes"
                                  placeholder=" "
                                  persistent-placeholder
                                />
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-container>

                      <v-card-actions>
                        <v-btn outlined rounded @click="previous()">
                          <v-icon small left>
                            fal fa-arrow-alt-left
                          </v-icon>
                          Back
                        </v-btn>
                        <v-spacer />
                        <v-btn rounded color="primary" @click="saveStep4()">
                          <v-icon small left>
                            fal fa-arrow-alt-down
                          </v-icon>
                          Save & Continue
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>

                <!--Confirmation-->
                <v-stepper-content step="5" class="elevation-0">
                  <v-form ref="step4" v-model="step4Valid" lazy-validation>
                    <v-card>
                      <v-container fluid grid-list-md>
                        <v-layout row wrap class="py-4">
                          <v-flex xs12 md12 class="pa-4">
                            <v-icon large color="primary">
                              fal fa-arrow-alt-down
                            </v-icon>
                            <span class="headline pl-2 secondary--text"
                              >Option 1 - Submit Order</span
                            >
                            <p class="py-2">
                              Ready to submit your order?
                            </p>
                            <p>
                              Information has been verified and this submission
                              will instruct us to start producing your order.
                              You will be able to come back and edit the order
                              up until the status of the order has reached
                              "Ready For Production".
                            </p>
                            <v-layout>
                              <v-btn
                                block
                                rounded
                                color="primary"
                                @click="saveStep5(order.uuid, 1)"
                              >
                                <v-icon small left>
                                  fal fa-check
                                </v-icon>
                                Submit Order
                              </v-btn>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap class="py-4">
                          <v-flex xs12 md12 class="pa-4">
                            <v-icon large color="deep-orange">
                              fal fa-drafting-compass
                            </v-icon>
                            <span class="headline pl-2 secondary--text"
                              >Option 2 - Save As Draft</span
                            >
                            <p class="py-2">
                              Not ready to submit the order yet?
                            </p>
                            <p class="pb-4">
                              No problem, just click the "Save As Pending"
                              option and come back to edit the order at any
                              time.
                            </p>
                            <v-layout>
                              <v-btn
                                block
                                rounded
                                color="deep-orange lighten-2 white--text"
                                @click="saveStep5(order.uuid, 0)"
                              >
                                <v-icon small left>
                                  fal fa-arrow-alt-down
                                </v-icon>
                                Save As Pending
                              </v-btn>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-container>

                      <v-card-actions>
                        <v-btn rounded outlined @click="previous()">
                          <v-icon small left>
                            fal fa-arrow-alt-left
                          </v-icon>
                          Back
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import { clientID, clientSecret, uploadHeader } from "@/env";
import CarrierAccountEntry from "@/components/CarrierAccountEntry";
import AddressEntry from "@/components/AddressEntry";
import TextDisplay from "@/components/TextDisplay";

export default {
  name: "OrderEdit",
  mixins: [mixin],
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      customersStore: state => state.customersStore,
      ordersStore: state => state.ordersStore,
      servicesStore: state => state.servicesStore,
      designLocationsStore: state => state.designLocationsStore,
      addressesStore: state => state.addressesStore,
      carrierAccountsStore: state => state.carrierAccountsStore,
      alertsStore: state => state.alertsStore,
      productsStore: state => state.productsStore
    }),
    lineItemTotal() {
      let xs = this.order.j_line_items[this.currentItem - 1].d_sizeXS;
      xs = xs || 0;
      let sm = this.order.j_line_items[this.currentItem - 1].d_sizeSM;
      sm = sm || 0;
      let md = this.order.j_line_items[this.currentItem - 1].d_sizeMD;
      md = md || 0;
      let lg = this.order.j_line_items[this.currentItem - 1].d_sizeLG;
      lg = lg || 0;
      let xl = this.order.j_line_items[this.currentItem - 1].d_sizeXL;
      xl = xl || 0;
      let xxl = this.order.j_line_items[this.currentItem - 1].d_sizeXXL;
      xxl = xxl || 0;
      let xxxl = this.order.j_line_items[this.currentItem - 1].d_sizeXXXL;
      xxxl = xxxl || 0;
      let xxxxl = this.order.j_line_items[this.currentItem - 1].d_size4XL;
      xxxxl = xxxxl || 0;
      let xxxxxl = this.order.j_line_items[this.currentItem - 1].d_size5XL;
      xxxxxl = xxxxxl || 0;
      let osfa = this.order.j_line_items[this.currentItem - 1].d_sizeOSFA;
      osfa = osfa || 0;
      let other = this.order.j_line_items[this.currentItem - 1].d_sizeOther;
      other = other || 0;

      let itemTotal =
        Number(xs) +
        Number(sm) +
        Number(md) +
        Number(lg) +
        Number(xl) +
        Number(xxl) +
        Number(xxxl) +
        Number(xxxxl) +
        Number(xxxxxl) +
        Number(osfa) +
        Number(other);
      itemTotal = isNaN(itemTotal) ? 0 : itemTotal;

      return itemTotal;
    }
  },
  components: {
    AddressEntry,
    CarrierAccountEntry,
    TextDisplay
  },
  data() {
    return {
      progress: true,
      upload: false,
      e1: 1,
      steps: 5,
      step1Valid: true,
      step2Valid: true,
      step3Valid: true,
      step4Valid: true,
      menu1: "",
      date1: "",
      dialog: false,
      deleteDialog: false,
      uploadArtwork: false,
      deleteMessage: "",
      deleteRep: "",
      deleteMode: "",
      currentItem: 1,
      file: "",
      files: [],
      fileDescription: "",
      fileLocation: "",
      stepHeaderData: [
        {
          title: "General Info"
        },
        {
          title: "Line Items"
        },
        {
          title: "File Upload"
        },
        {
          title: "Delivery Options & Notes"
        },
        {
          title: "Confirmation"
        }
      ],
      priorities: [
        {
          id: 1,
          name: "Yes"
        },
        {
          id: 0,
          name: "No"
        }
      ],
      order: {
        id: "",
        uuid: "",
        fk_customerID: "",
        fk_shippingCarrierID: "",
        fk_shippingMethodID: "",
        fk_addressID_From: "",
        fk_addressID_To: "",
        fk_userID: "",
        fk_carrierAccountID: "",
        d_dateOrder: "",
        d_dateDue: "",
        d_orderNumber: "",
        d_jobName: "",
        d_customerPurchaseOrder: "",
        d_customerPORepeat: "",
        f_pickUp: "",
        f_splitShipment: "",
        f_status: "",
        d_type: "",
        f_priority: "",
        f_new: "",
        f_design: "",
        f_download: 0,
        d_deliveryNote: "",
        j_artwork: [],
        j_line_items: [
          {
            id: "",
            fk_orderID: "",
            fk_customerID: "",
            d_color: "",
            d_description: "",
            d_productItemNumber: "",
            fk_productID: "",
            d_sizeXS: "",
            d_sizeSM: "",
            d_sizeMD: "",
            d_sizeLG: "",
            d_sizeXL: "",
            d_sizeXXL: "",
            d_sizeXXXL: "",
            d_size4XL: "",
            d_size5XL: "",
            d_sizeOther: "",
            d_sizeOSFA: "",
            d_price: 0,
            j_images: [
              {
                id: "",
                akf_itemService: "",
                akf_imageLocationID: "",
                akf_imageID: "",
                akf_itemOptions: [],
                d_instructions: "",
                d_designName: ""
              }
            ]
          }
        ]
      },
      shippingCarriers: [
        {
          fk_internalID: 1,
          d_name: "UPS"
        },
        {
          fk_internalID: 2,
          d_name: "FedEx"
        },
        {
          fk_internalID: 3,
          d_name: "USPS"
        },
        {
          fk_internalID: 4,
          d_name: "Courier"
        }
      ],
      shippingMethods: [
        {
          fk_internalID: 1,
          d_name: "Ground"
        },
        {
          fk_internalID: 2,
          d_name: "2nd Day"
        },
        {
          fk_internalID: 3,
          d_name: "Next Day Air"
        }
      ],
      address: {
        mode: "",
        open: "",
        valid: true,
        id: "",
        fk_customerID: "",
        d_locationName: "",
        d_company: "",
        d_street1: "",
        d_street2: "",
        d_attention: "",
        d_city: "",
        d_state: "",
        d_zip: "",
        d_country: "",
        f_status: "",
        f_shipping: "",
        f_return: "",
        f_new: "",
        f_default: "",
        f_show: ""
      },
      carrierAccount: {
        mode: "",
        open: "",
        valid: true,
        id: "",
        fk_customerID: "",
        fk_shippingCarrierID: "",
        d_account: "",
        d_companyName: "",
        d_street1: "",
        d_street2: "",
        d_city: "",
        d_state: "",
        d_zip: "",
        d_country: "",
        f_default: "",
        f_status: ""
      },
      rules: {
        f_type: [v => !!v || "required"],
        f_new: [v => !!v || "required"],
        f_design: [v => !!v || "required"],
        f_pickUp: [v => !!v || "required"],
        d_customerPurchaseOrder: [v => !!v || "required"],
        d_jobName: [v => !!v || "required"],
        fk_userID: [v => !!v || "required"],
        akf_imageLocationID: [v => !!v || "required"],
        akf_itemService: [v => !!v || "required"],
        fileDescription: [v => !!v || "required"],
        fileLocation: [v => !!v || "required"],
        files: [v => !!v || "required"],
        d_dateDue: [v => !!v || "required"]
      }
    };
  },

  watch: {
    date1(val) {
      this.order.d_dateDue = this.formatDate(this.date1);
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.progress = true;

      const user = this.$store.getters["usersStore/getAuthUser"];

      if (!user) {
        this.$router.push({ name: "logout" });
      } else {
        const data = {
          fk_customerID: user.fk_customerID
        };

        /* Fetch Customer Users */
        await this.$store.dispatch("usersStore/fetchByCustomer", data);

        if (this.$route.params.orderUUID) {
          const uuid = this.$route.params.orderUUID;

          this.currentItem = this.$route.params.item;
          const order = await this.$store.dispatch(
            "ordersStore/readUUID",
            uuid
          );
          // const order = this.$store.getters['ordersStore/getOrder'];
          this.order = { ...order };
          if (order.d_dateDue) {
            this.order.d_dateDue = this.formatDate(order.d_dateDue);
            this.date1 = order.d_dateDue;
          }

          this.progress = false;
        } else {
          /* Set Today's Date */
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth() + 1; // January is 0!
          const yyyy = today.getFullYear();

          if (dd < 10) {
            dd = `0${dd}`;
          }

          if (mm < 10) {
            mm = `0${mm}`;
          }
          today = `${yyyy}-${mm}-${dd}`;
          this.order.d_dateOrder = today;

          /* Auto Set To Pending */
          this.order.fk_customerID = user.fk_customerID;
          this.order.f_status = 999;
          this.order.fk_userID = user.id;
          this.order.d_orderNumber = `PENDING - ${yyyy}${mm}${dd}.${Math.floor(
            Math.random() * 10000
          )}`;
          this.progress = false;
        }
      }
    },

    stepHeader(step) {
      return this.stepHeaderData[step - 1].title;
    },

    previous() {
      this.e1--;
    },

    next() {
      this.e1++;
    },

    paginate(value) {
      this.currentItem = value;
    },

    openDeleteDialog(mode, message, rep) {
      this.deleteMode = mode;
      this.deleteMessage = message;
      this.deleteRep = rep;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    goOrder(uuid) {
      if (this.$route.name === "orderNew") {
        this.$router.push({ name: "orders" });
      } else {
        this.$router.push({ name: "orderDetail", params: { orderUUID: uuid } });
      }
    },

    addItem() {
      const data = {
        id: "",
        fk_orderID: "",
        fk_customerID: "",
        d_color: "",
        d_description: "",
        d_productItemNumber: "",
        fk_productID: "",
        d_productStyle: "",
        d_sizeXS: "",
        d_sizeMD: "",
        d_sizeLG: "",
        d_sizeXL: "",
        d_sizeXXL: "",
        d_sizeXXXL: "",
        d_size4XL: "",
        d_size5XL: "",
        d_sizeOther: "",
        d_sizeOSFA: "",
        d_price: 0,
        j_images: []
      };
      this.order.j_line_items.push(data);
      this.currentItem = this.order.j_line_items.length;
    },

    removeItem() {
      this.closeDeleteDialog();
      if (this.deleteMode === 1.1) {
        this.order.j_line_items.splice(this.deleteRep, 1);
      } else {
        this.order.j_line_items.splice(this.currentItem - 1, 1);
        if (this.currentItem !== 1) {
          this.currentItem -= 1;
        }
      }
    },

    addDecorating() {
      const data = {
        id: "",
        akf_itemService: "",
        akf_imageLocationID: "",
        akf_imageID: "",
        akf_itemOptions: "",
        d_instructions: ""
      };
      this.order.j_line_items[this.currentItem - 1].j_images.push(data);
    },

    removeDecorating(i) {
      this.closeDeleteDialog();
      this.order.j_line_items[this.currentItem - 1].j_images.splice(i, 1);
    },

    async saveStep1(uuid) {
      if (this.$refs.step1.validate()) {
        this.order.date1 = this.date1;

        if (uuid) {
          await this.$store.dispatch("ordersStore/update", this.order);
        } else {
          await this.$store.dispatch("ordersStore/create", this.order);

          const savedOrder = this.$store.getters["ordersStore/getOrder"];

          this.order.id = savedOrder.id;

          /* New Order And Screen Print Order */
          if (this.order.f_new === 1 && this.order.f_type === 2) {
            /* Set New Screen Charge */
            this.order.j_line_items[0].j_images[0].akf_itemOptions = [5];
            /* Set Screen Print */
            this.order.j_line_items[0].j_images[0].akf_itemService = 4;
          } else if (this.order.f_new === 2 && this.order.f_type === 2) {
            /* Repeat Order And Screen Print Order */
            /* Set Repeat Screen Charge */
            this.order.j_line_items[0].j_images[0].akf_itemOptions = [30];
          } else if (
            /* New Order And Embroidery Order And Digitizing Checked */
            this.order.f_new === 1 &&
            this.order.f_type === 1 &&
            this.order.f_design === 2
          ) {
            /* Set Digitizing */
            this.order.j_line_items[0].j_images[0].akf_itemService = 13;
          }
        }

        const data = {
          f_orderType: this.order.f_type
        };

        if (this.order.f_type === 3) {
          /* Fetch products */
          await this.$store.dispatch("productsStore/valueList", data);
        } else {
          await this.$store.dispatch("servicesStore/fetch", data);

          await this.$store.dispatch("servicesStore/fetchOptions", data);

          await this.$store.dispatch("designLocationsStore/valueList", data);
        }

        this.e1++;
      }
    },

    async saveStep2() {
      if (this.$refs.step2.validate()) {
        await this.$store.dispatch("ordersStore/update", this.order);

        this.e1++;
      }
    },

    async saveStep3() {
      if (this.$refs.step3.validate()) {
        await this.$store.dispatch("ordersStore/update", this.order);

        const user = this.$store.getters["usersStore/getAuthUser"];

        const data = {
          fk_customerID: user.fk_customerID,
          fk_shippingCarrierID: this.order.fk_shippingCarrierID
        };
        await this.$store.dispatch(
          "carrierAccountsStore/fetchByCustomerCarrier",
          data
        );
        await this.$store.dispatch(
          "addressesStore/fetchByCustomerReturn",
          data
        );
        await this.$store.dispatch("addressesStore/fetchByCustomerShip", data);

        this.e1++;
      }
    },

    async saveStep4() {
      await this.$store.dispatch("ordersStore/update", this.order);
      this.e1++;
    },

    async saveStep5(uuid, mode) {
      if (this.$refs.step4.validate()) {
        this.order.f_download = mode;

        await this.$store.dispatch("ordersStore/update", this.order);
        this.goOrder(uuid);
      }
    },

    async postImage() {
      this.file = this.files[0];
      const formData = new FormData();
      formData.append("file", this.files[0]);
      formDate.append("orderID", this.order.id);
      const response = await axios.post("/api/artwork/submit", formData, {
        headers: uploadHeader()
      });
    },

    async addFile() {
      if (this.$refs.artwork.validate()) {
        this.file = this.files[0];
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("customerID", this.order.fk_customerID);
        formData.append("orderID", this.order.id);
        this.uploadArtwork = false;
        this.upload = true;
        const response = await axios.post("/api/artwork/submit", formData, {
          headers: uploadHeader()
        });
        this.upload = false;
        if (response.status === 200) {
          const newFile = {
            d_description: this.fileDescription,
            d_location: this.fileLocation,
            d_path: response.data
          };
          this.$refs.artwork.reset();
          if (!this.order.j_artwork) {
            this.order.j_artwork = [];
          }
          this.order.j_artwork.push(newFile);
          this.openSnackbar(1);
        } else {
          this.openSnackbar(2);
        }
      }
    },

    openArtworkDialog() {
      this.uploadArtwork = true;
    },

    removeFile(id) {
      this.closeDeleteDialog();
      this.order.j_artwork.splice(id, 1);
    },

    openSnackbar(code) {
      let data;
      if (code === 1) {
        data = {
          color: "green lighten-1",
          text: "File has been successfully uploaded."
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text:
            "There was an error uploading the file.  Try again and contact support if the issue continues."
        };
      }
      this.$store.commit("alertsStore/setAlert", data);
    },

    async carrierAccounts(carrierID) {
      this.order.fk_carrierAccountID = "";

      const user = this.$store.getters["usersStore/getAuthUser"];

      const data = {
        fk_customerID: user.fk_customerID,
        fk_shippingCarrierID: carrierID
      };
      await this.$store.dispatch(
        "carrierAccountsStore/fetchByCustomerCarrier",
        data
      );
    },

    async pullProductDetails(index, productID, qty) {
      let data = {
        fk_productID: productID,
        d_sizeOther: qty
      };
      if (productID) {
        await this.$store.dispatch("productsStore/read", data);
        let product = this.$store.getters["productsStore/getProduct"];
        this.order.j_line_items[index].d_productItemNumber =
          product.d_itemNumber;
        this.order.j_line_items[index].d_color = product.d_color;
        this.order.j_line_items[index].d_description = product.d_description;
      }
      if (productID && qty) {
        let productPrice = await this.$store.dispatch(
          "productsStore/productPrice",
          data
        );
        this.order.j_line_items[index].d_price = productPrice.d_price;
      }
    }
  }
};
</script>

<style scoped>
button {
  outline: none;
}
</style>
